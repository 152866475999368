import React from 'react';

type Config = IntersectionObserverInit & {
  initialState?: boolean;
  once?: boolean;
};

export default function useIntersectionObserver(config: Config = {}) {
  const { initialState = false, once = false, ...restConfig } = config;

  const [isIntersecting, setIsIntersecting] = React.useState(initialState);
  const previousObserver = React.useRef<IntersectionObserver | null>(null);

  const customRef = React.useCallback(
    (node: any) => {
      if (previousObserver.current) {
        previousObserver.current.disconnect();
        previousObserver.current = null;
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const options = { threshold: 0.5, root: null, rootMargin: '0px', ...restConfig };

        const observer = new IntersectionObserver(([entry]) => {
          if (isIntersecting && once) return;

          const newVal = entry?.isIntersecting ?? false;

          if (isIntersecting !== newVal) {
            setIsIntersecting(newVal);
          }
        }, options);

        observer.observe(node);
        previousObserver.current = observer;
      }
    },
    [isIntersecting, once, restConfig]
  );

  return { isIntersecting: isIntersecting ?? false, setIsIntersecting, customRef } as {
    isIntersecting: boolean;
    setIsIntersecting: React.Dispatch<React.SetStateAction<boolean>>;
    customRef: (node: Element | null) => void;
  };
}
