import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import {
  Checkbox,
  DatePicker,
  FormSection,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
} from '~/components/shared/form';
import { PLAN_TYPE } from '~/constants/classifications';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Client } from '~/models';
import colors from '~/styles/theme/colors';

import { ReducedConfigs } from './types';

function ConfigureCareOptionsForm(props: ConfigureCareOptionsFormProps) {
  const { selectedTabValue, client } = props;

  const {
    setFieldValue,
    status: { disabled },
    values,
  } = useFormikContext<ReducedConfigs>();

  const commonSelectProps = {
    closeMenuOnSelect: false,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true,
  };

  const commonMultiSelectProps = {
    ...commonSelectProps,
    component: MultiSelect,
    labelComponent: MultiSelectLabel,
  };

  const planTypeClassificationsFieldName = `${selectedTabValue}.utilizationManagement.planTypeClassification`;
  const utilizationManagerCheckboxFieldName = `${selectedTabValue}.utilizationManager`;
  const umCheckboxFieldName = `${selectedTabValue}.utilizationManagement.enabled`;
  const caseManagerCheckboxFieldName = `${selectedTabValue}.caseManager`;
  const pddCheckboxFieldName = `${selectedTabValue}.projectedDischarge.enabled`;
  const launchDateFieldName = `${selectedTabValue}.launchDate`;

  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: client?.id,
    params: { type: PLAN_TYPE, client: client.id },
  });

  const handleUmEnabledChange = (enabled: boolean) => {
    if (!enabled) {
      setFieldValue(planTypeClassificationsFieldName, []);
    }
  };

  // early return out of component if no active tab
  // or no form values to prevent rendering errors
  if (!selectedTabValue || JSON.stringify(values) === '{}') return null;
  const visible = values[selectedTabValue].utilizationManagement.enabled;

  const viewingGroupType = client.clientGroupTypes.find((groupType) => groupType.id === selectedTabValue);

  return (
    <Container>
      <Card>
        <SectionHeader>Launch Date</SectionHeader>
        <StyledInputGroup
          name={launchDateFieldName}
          label={`${viewingGroupType?.groupType.displayName} Launch Date`}
          component={DatePicker}
          clearable={false}
          minDate={new Date()}
        />
      </Card>
      <Card>
        <SectionHeader>Enabled Features</SectionHeader>
        <StyledFormSection>
          <StyledCheckBox
            name={utilizationManagerCheckboxFieldName}
            label='Utilization Manager Field'
            labelSize='14px'
            data-cy='utilizationManagerFieldConfigureCareOptionsCheckbox'
            checkedColor={colors.primaryBlue}
            disabled={disabled}
            size={14}
          />
          <StyledCheckBox
            name={umCheckboxFieldName}
            label='UM Authorizations'
            labelSize='14px'
            data-cy='umAuthorizationsConfigureCareOptionsCheckbox'
            checkedColor={colors.primaryBlue}
            disabled={disabled}
            onChange={handleUmEnabledChange}
            size={14}
          />
          <StyledInputGroup
            {...commonMultiSelectProps}
            {...planTypesAsyncOptions}
            name={planTypeClassificationsFieldName}
            data-cy='umConfigureCareOptionsDropdown'
            label='UM Enabled Plan Types'
            disabled={disabled}
            placeholder='All Plan Types'
            visible={visible}
            selectedCountDefault='All'
          />
          <HR />
          <StyledCheckBox
            name={caseManagerCheckboxFieldName}
            label='Case Manager Field'
            labelSize='14px'
            data-cy='caseManagerFieldConfigureCareOptionsCheckbox'
            checkedColor={colors.primaryBlue}
            disabled={disabled}
            size={14}
          />
          <StyledCheckBox
            name={pddCheckboxFieldName}
            label='Projected Discharge Date'
            labelSize='14px'
            data-cy='pddConfigureCareOptionsCheckbox'
            checkedColor={colors.primaryBlue}
            disabled={disabled}
            size={14}
          />
        </StyledFormSection>
      </Card>
    </Container>
  );
}

ConfigureCareOptionsForm.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
  selectedTabValue: PropTypes.string,
};

type ConfigureCareOptionsFormProps = InferProps<typeof ConfigureCareOptionsForm.propTypes>;

export default ConfigureCareOptionsForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Card = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.black10};
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
  width: 500px;
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.black10};
  margin: 16px 0;
`;

const StyledFormSection = styled(FormSection)`
  display: flex;
  flex-direction: column;
`;

const StyledInputGroup = styled(InputGroup)`
  * {
    min-height: 4px;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  margin-top: 16px;

  &:first-child {
    margin-top: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
