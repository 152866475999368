import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { faker } from '@faker-js/faker';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import DataTable from '~/components/shared/DataTable';
import { InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import Slideout from '~/components/shared/Slideout';
import TrashIcon from '~/components/shared/svg/TrashIcon';
import { LabelBold } from '~/components/shared/typography';
import { getId } from '~/helpers';
import { useAddressOptions } from '~/lib/hooks';
import { Group } from '~/models';
import { colors } from '~/styles/theme';

type NetworkGroup = Partial<Group> & {
  id: string;
  name: string;
  groupTypeId: string;
  groupTypeDisplayName: string;
  clientName: string;
};

type Props = {
  groupTypeId: string;
  groupTypeDisplayName: string;
  groups: NetworkGroup[];
  handleClose: () => void;
  handleApply: (groupTypeId: string, groups: NetworkGroup[]) => void;
};

function AssociatedGroupsSlideout({ groupTypeId, groupTypeDisplayName, groups, handleClose, handleApply }: Props) {
  const [selectedGroups, setSelectedGroups] = useState<NetworkGroup[]>(groups || []);
  const [search, setSearch] = useState('');
  const addressProps = useAddressOptions();

  // These are mocked options coming back from the admin group controller index route.
  // Replace this with the `useAsyncOptions` hook and group types thunk in #188678514
  const groupsFromApi = useMemo<Partial<Group>[]>(
    () =>
      Array.from({ length: 5 }).map(() => ({
        id: faker.string.uuid(),
        name: faker.animal.cow(),
        groupTypeId,
        groupType: {
          displayName: groupTypeDisplayName,
        },
        client: {
          name: faker.animal.petName(),
        },
        address: {
          city: faker.location.city(),
          state: faker.location.state(),
        },
      })) as Partial<Group>[],
    []
  );

  // Don't show groups that are already associated (listed in the table of selected groups)
  const groupsOptions = useMemo(
    () => groupsFromApi.filter((group) => !selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)),
    [groupsFromApi, selectedGroups]
  );

  const tableData = useMemo(
    () => selectedGroups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())),
    [search, selectedGroups]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleSearchClear = () => setSearch('');

  const handleAddGroup = (group: Group) => {
    setSelectedGroups((prevGroups) => {
      const { id, name, groupTypeId, groupType, client } = group;

      return [
        ...prevGroups,
        { id, name, groupTypeId, groupTypeDisplayName: groupType?.displayName, clientName: client?.name },
      ];
    });
  };

  const handleRemoveGroup = (id: string) => {
    setSelectedGroups((prevGroups) => prevGroups.filter((group) => group.id !== id));
  };

  const handleApplyAndClose = () => {
    handleApply(groupTypeId, selectedGroups);
    handleClose();
  };

  const columnHelper = createColumnHelper<NetworkGroup>();

  const columns = useMemo<ColumnDef<NetworkGroup, string>[]>(
    () => [
      columnHelper.accessor('name', {
        header: groupTypeDisplayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('clientName', {
        header: 'Client',
        cell: (data) => (
          <ClientCell>
            <LabelBold>{data.getValue()}</LabelBold>
            <StyledTrashIcon color={colors.accentRed} onClick={() => handleRemoveGroup(data.row.original.id)} />
          </ClientCell>
        ),
      }),
    ],
    [groupTypeDisplayName, handleRemoveGroup]
  );

  return (
    <Slideout title={groupTypeDisplayName} handleClose={handleClose}>
      {{
        content: (
          <SlideoutContent>
            <InputGroup
              {...addressProps}
              component={Select}
              getOptionValue={getId}
              options={groupsOptions}
              onChange={handleAddGroup}
              closeMenuOnSelect={false}
              autoSelectSingleOption={false}
              value={null}
              name='associatedGroups'
              label={`Select ${groupTypeDisplayName}s`}
              placeholder='Select'
            />
            <TableBar>
              <ItemsCount>
                {selectedGroups.length} Associated {groupTypeDisplayName}s
              </ItemsCount>
              <Search value={search} onChange={handleSearchChange} onClear={handleSearchClear} />
            </TableBar>
            <DataTable columns={columns} data={tableData}></DataTable>
          </SlideoutContent>
        ),
        footer: (
          <SlideoutFooter>
            <ButtonGroup>
              <Button color='transparent' text='Cancel' onClick={handleClose} />
              <Button color='primary' text='Apply' onClick={handleApplyAndClose} />
            </ButtonGroup>
          </SlideoutFooter>
        ),
      }}
    </Slideout>
  );
}

export default AssociatedGroupsSlideout;

const SlideoutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SlideoutFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  *:only-child {
    margin-left: auto;
  }

  padding: 16px 24px;
`;

const TableBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
`;

const ItemsCount = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
`;

const ClientCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;
