import styled, { css } from 'styled-components';

import { useLinger } from '~/lib/hooks';
import { colors } from '~/styles/theme';

type FoldoutPosition = 'left' | 'right';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  position?: FoldoutPosition;
  closeOnBackdropClick?: boolean;
  setOpen?: (open: boolean) => void;
}

export default function Foldout({
  children,
  open,
  position = 'right',
  closeOnBackdropClick = false,
  setOpen,
  ...rest
}: Props) {
  const linger = useLinger(open, 500);

  return (
    <>
      <Backdrop
        $open={open}
        onClick={() => {
          if (closeOnBackdropClick) setOpen?.(false);
        }}
      />

      <Slideout $open={open} $position={position} {...rest}>
        {linger && children}
      </Slideout>
    </>
  );
}

export const Backdrop = styled.div<{ $open: boolean }>`
  z-index: 800;
  position: absolute;
  pointer-events: ${({ $open }) => ($open ? 'initial' : 'none')};
  background-color: ${({ $open }) => ($open ? colors.black50 : 'transparent')};
  transition: background-color 0.5s;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Slideout = styled.div<{ $open: boolean; $position: FoldoutPosition }>`
  z-index: 900;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: white;
  transition: transform 0.5s;
  width: 50vw;
  overflow-y: auto;

  ${({ $open, $position }) =>
    $position === 'left'
      ? css`
          left: 0;
          transform: translateX(-${$open ? '0' : '100'}%);
        `
      : css`
          right: 0;
          transform: translateX(${$open ? '0' : '100'}%);
        `};
`;
