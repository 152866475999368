import React from 'react';

const LauncherRightCaretIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill, height = 10, width = 7 } = props;

  return (
    <svg width={width} height={height} viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.570339 1.00104C0.570395 1.26657 0.675922 1.5212 0.863706 1.70892L4.16083 5.00604L0.863706 8.30317C0.68132 8.49201 0.5804 8.74492 0.582681 9.00745C0.584962 9.26997 0.690262 9.5211 0.875903 9.70674C1.06154 9.89238 1.31267 9.99768 1.57519 9.99996C1.83772 10.0022 2.09064 9.90132 2.27948 9.71894L6.28448 5.71393C6.47219 5.52617 6.57764 5.27154 6.57764 5.00604C6.57764 4.74055 6.47219 4.48592 6.28448 4.29816L2.27948 0.293154C2.13945 0.153169 1.96106 0.0578432 1.76687 0.019228C1.57267 -0.0193882 1.37139 0.00043869 1.18846 0.0762033C1.00553 0.151968 0.84917 0.28027 0.739148 0.444885C0.629127 0.609501 0.570381 0.803041 0.570339 1.00104Z'
        fill={fill}
      />
    </svg>
  );
};

export default LauncherRightCaretIcon;
