import React from 'react';
import styled from 'styled-components';

import { colors } from '~/styles/theme';

import LauncherRightCaretIcon from './svg/LauncherRightCaretIcon';

type Props = {
  label: string;
  subLabel?: string;
  enabled?: boolean;
  handleLaunch: () => void;
};

const Launcher = ({ label, subLabel, enabled, handleLaunch }: Props) => {
  return (
    <Container onClick={handleLaunch}>
      <LabelGroup>
        <Label>
          {label}
          {enabled && <Pill>Enabled</Pill>}
        </Label>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </LabelGroup>
      <StyledCaret fill={colors.black} />
    </Container>
  );
};

export default Launcher;

const Container = styled.div`
  height: 64px;
  border: 1px solid ${colors.black25};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    border-color: ${colors.primaryBlue};
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
  display: flex;
  align-items: center;
`;

const SubLabel = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${colors.black};
  padding-top: 4px;
`;

const Pill = styled.span`
  font-size: 10px;
  color: #2d550c;
  border: 0.5px solid #2d550c;
  background-color: #f7fcf3;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 16px;
`;

const StyledCaret = styled(LauncherRightCaretIcon)`
  right: 0;
`;

const SlideoutContainer = styled.div`
  width: 50vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #d9d9d9;
`;
