import styled from 'styled-components';

import { colors } from '~/styles/theme';

import { H3 } from './typography';

type Props = {
  title: string;
  children: {
    content: React.ReactNode;
    footer: React.ReactNode;
  };
  handleClose: () => void;
};

const Slideout = ({ title, children: { content, footer }, handleClose }: Props) => {
  return (
    <Container>
      <Header>
        <H3>{title}</H3>
        <CloseIcon onClick={handleClose}>&times;</CloseIcon>
      </Header>
      <Content>{content}</Content>
      <Footer>{footer}</Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const CloseIcon = styled.div`
  margin-left: auto;
  color: ${colors.black75};
  cursor: pointer;
  font-size: 24px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.black10};
  padding: 24px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  padding: 24px;
  overflow-y: auto;
`;
const Footer = styled.div`
  margin-top: auto;
  border-top: 1px solid ${colors.black10};
`;

export default Slideout;
