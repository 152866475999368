type Props = React.SVGProps<SVGSVGElement> & {
  direction: 'asc' | 'desc' | 'none';
};

export default function ArrowUpDownIcon({ direction, height, width, ...props }: Props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height={height} width={width} fill='none' {...props}>
      <g fill='currentColor' opacity={direction !== 'desc' ? 1 : 0.5} clipPath='url(#a)'>
        <path d='M2.205 2.622a.417.417 0 0 0 .295.711h5a.417.417 0 0 0 .295-.711l-2.5-2.5a.417.417 0 0 0-.589 0l-2.5 2.5Z' />
      </g>
      <g fill='currentColor' opacity={direction !== 'asc' ? 1 : 0.5} clipPath='url(#a)'>
        <path d='M4.705 9.878a.417.417 0 0 0 .59 0l2.5-2.5a.417.417 0 0 0-.295-.711h-5a.417.417 0 0 0-.295.711l2.5 2.5Z' />
      </g>
      <defs>
        <clipPath id='arrow-up-down-icon'>
          <path fill='#fff' d='M0 10V0h10v10z' />
        </clipPath>
      </defs>
    </svg>
  );
}
