import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { faker } from '@faker-js/faker';

import AssociatedGroupsSlideout from '~/components/clients/clientForm/AssociatedGroupsSlideout';
import { getClientTypeName } from '~/components/clients/helpers';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import Foldout from '~/components/shared/Foldout';
import {
  Checkbox,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import Launcher from '~/components/shared/Launcher';
import { H2Bold } from '~/components/shared/typography';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { CLIENT_TYPES, PAYOR } from '~/constants/clientTypes';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getKind, getName } from '~/helpers';
import { sortBy } from '~/helpers/sortBy';
import { useAsyncOptions } from '~/lib/hooks';
import { ALL_FLAGS } from '~/models';
import Client, { MFA_FACTORS, MfaConfig } from '~/models/Client';
import GroupType from '~/models/GroupType';
import { useProfileContext } from '~/services/profile';

const filterMfaOptionsByIndependence = (val) =>
  Object.entries(MFA_FACTORS)
    .filter(([_value, { independent }]) => independent === val)
    .map(([value, { label }]) => ({ name: label, id: value }));

function ClientForm(props) {
  const { dirty, isValid, isSubmitting, onCancel, setValues, values } = props;
  const { id, children } = values;
  const clientTypes = CLIENT_TYPES.map((type) => ({ name: type, kind: type }));

  const profileSvc = useProfileContext();

  // These are mocked `client_network_groups` already selected for the client
  // coming back from the admin clients controller index route.
  // Replace this with the real data in #188678514
  const clientNetworkGroups = useMemo(
    () =>
      Array.from({ length: 3 }).map(() => ({
        id: faker.string.uuid(),
        name: faker.animal.cow(),
        groupTypeId: faker.string.uuid(),
        groupTypeDisplayName: faker.animal.horse(),
        clientName: faker.animal.petName(),
      })),
    []
  );

  // Group the client network groups by group type, with the group type id as the key
  // and the group type data + list of its groups as the value
  const clientNetworkGroupTypes = useMemo(
    () =>
      clientNetworkGroups.reduce((acc, group) => {
        if (!acc[group.groupTypeId]) {
          const { groupTypeId, groupTypeDisplayName } = group;

          acc[group.groupTypeId] = { groupTypeId, groupTypeDisplayName, groups: [] };
        }

        acc[group.groupTypeId].groups.push(group);

        return acc;
      }, {}),
    [clientNetworkGroups]
  );

  const [associatedGroupTypesWithGroups, setAssociatedGroupTypesWithGroups] = useState(clientNetworkGroupTypes);
  const [showSlideout, setShowSlideout] = useState(false);
  const [slideoutGroupType, setSlideoutGroupType] = useState(null);

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: EPISODE } });
  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: PLAN_TYPE } });
  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes, { params: { sortBy: 'name asc', type: PROVIDER } });
  const mfaIndependentFactorOptions = filterMfaOptionsByIndependence(true);
  const mfaDependentFactorOptions = filterMfaOptionsByIndependence(false);
  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    optionsTransform: (clients) => {
      return clients.filter((clientOption) => clientOption.id !== id);
    },
  });

  // These are mocked options coming back from the admin group types controller index route.
  // Replace this with the `useAsyncOptions` hook and group types thunk in #188678514
  const groupTypesFromApi = useMemo(
    () =>
      Array.from({ length: 5 }).map(() => ({
        id: faker.string.uuid(),
        displayName: faker.animal.horse(),
      })),
    []
  );

  // Don't show group types that are already associated with the client
  const groupTypesOptions = useMemo(
    () => groupTypesFromApi.filter((gt) => !associatedGroupTypesWithGroups[gt.id]),
    [groupTypesFromApi, associatedGroupTypesWithGroups]
  );

  const commonSelectProps = {
    autoSelectSingleOption: false,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true,
    component: Select,
  };
  const commonMultiSelectProps = {
    ...commonSelectProps,
    component: MultiSelect,
    labelComponent: MultiSelectLabel,
  };

  const handleSsoChange = (val) => {
    if (val) {
      setValues({
        ...values,
        ssoEnabled: true,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleMfaEnabledChange = (val) => {
    if (!val) {
      setValues({
        ...values,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleAllowedIndependentFactorsChange = (factors) => {
    if (factors.length === 0 || !factors.some((factor) => factor.id === values.mfaConfig.default?.id)) {
      setValues({
        ...values,
        mfaConfig: {
          ...values.mfaConfig,
          allowedIndependentFactors: factors,
          default: null,
        },
      });
    }
  };

  const formatParentClientLabel = (client) => {
    const clientType = client.clientType === PAYOR ? 'Payer' : client.clientType;

    return `${client.name} (${clientType})`;
  };

  const handleAddGroupType = (groupType) => {
    setAssociatedGroupTypesWithGroups((prev) => {
      return {
        ...prev,
        [groupType.id]: { groupTypeId: groupType.id, groupTypeDisplayName: groupType.displayName, groups: [] },
      };
    });
  };

  const handleOpenSlideout = (groupType) => {
    setSlideoutGroupType(groupType);
    setShowSlideout(true);
  };

  const handleCloseSlideout = () => {
    setShowSlideout(false);
  };

  const handleApplySlideoutChanges = (groupTypeId, groups) => {
    setAssociatedGroupTypesWithGroups((prev) => ({
      ...prev,
      [groupTypeId]: { ...prev[groupTypeId], groups },
    }));
    setShowSlideout(false);
  };

  return (
    <Form>
      <FormHeader title={id ? 'Edit Client' : 'Add Client'} />
      <FormSection>
        <SectionHeader>Organization Setup</SectionHeader>
        <InputGroup name='name' label='Client Name' placeholder='Client Name' component={Input} />
        <InputGroup
          name='clientType'
          data-cy='clientType'
          label='Client Type'
          placeholder='Client Type'
          options={sortBy(clientTypes, 'name')}
          getOptionLabel={getClientTypeName}
          getOptionValue={getKind}
          component={Select}
          disabled={Boolean(children.length)}
        />
        <InputGroup
          {...commonSelectProps}
          {...clientsAsyncOptions}
          name='parent'
          data-cy='parent'
          getOptionLabel={formatParentClientLabel}
          label='Parent Client (optional)'
          placeholder='Parent Client'
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...providerAsyncOptions}
          name='groupTypes'
          data-cy='groupTypes'
          label='Enabled Care Options (optional)'
          placeholder='Enabled Care Options'
          getOptionLabel={getDisplayName}
          closeMenuOnSelect={false}
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='children'
          data-cy='children'
          label='Associated Clients (optional)'
          placeholder='Associated Clients'
          disabled
          visible={Boolean(children.length)}
        />
        <ClientCheckbox name='ssoEnabled' label='SSO Enabled' onChange={handleSsoChange} />
        <InputGroup
          name='fileRetentionDays'
          label='File Retention Days (optional)'
          placeholder='File Retention Days'
          component={Input}
        />
        <SectionHeader>Multi-factor Authentication (MFA) Configuration</SectionHeader>
        <ClientCheckbox
          name='mfaConfig.enabled'
          label='MFA Enabled'
          disabled={values.ssoEnabled}
          onChange={handleMfaEnabledChange}
        />
        <InputGroup
          {...commonMultiSelectProps}
          options={mfaIndependentFactorOptions}
          name='mfaConfig.allowedIndependentFactors'
          data-cy='allowedIndependentFactors'
          label='Enabled Independent Factors'
          placeholder='Enabled Independent Factors'
          onChange={handleAllowedIndependentFactorsChange}
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <InputGroup
          {...commonSelectProps}
          options={values.mfaConfig.allowedIndependentFactors}
          name='mfaConfig.default'
          data-cy='defaultFactor'
          label='Default Factor'
          placeholder='Default Factor'
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <InputGroup
          {...commonMultiSelectProps}
          options={mfaDependentFactorOptions}
          name='mfaConfig.allowedDependentFactors'
          data-cy='allowedDependentFactors'
          label='Enabled Dependent Factors (optional)'
          placeholder='Enabled Dependent Factors'
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <SectionHeader>Episodes and Plan Types</SectionHeader>
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='episodeClassifications'
          data-cy='episodeClassifications'
          label='Episode Types'
          placeholder='Episodes'
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...planTypesAsyncOptions}
          name='planTypeClassifications'
          data-cy='planTypeClassifications'
          label='Plan Types'
          placeholder='Plan Types'
        />
        {profileSvc.hasFlag(ALL_FLAGS.networkGroupsToClient) && (
          <>
            {/* Remove this div in #188678514 */}
            <div style={{ height: 50 }}>
              <H2Bold>Slideout Launcher experimental playground:</H2Bold>
            </div>
            {/* Modify this input to retrieve admin group types in #188678514 */}
            <InputGroup
              {...commonSelectProps}
              getOptionLabel={getDisplayName}
              options={groupTypesOptions}
              onChange={handleAddGroupType}
              value={null}
              name='associatedGroupTypes'
              label={'Add a horse'}
              placeholder='Add a horse'
            />
            {Object.values(associatedGroupTypesWithGroups).map((groupType) => (
              <Launcher
                key={groupType.groupTypeId}
                label={groupType.groupTypeDisplayName}
                subLabel={`${groupType.groups.length} associated`}
                handleLaunch={() => handleOpenSlideout(groupType)}
                enabled
              />
            ))}
            <Foldout open={showSlideout}>
              {slideoutGroupType && (
                <AssociatedGroupsSlideout
                  {...slideoutGroupType}
                  handleClose={handleCloseSlideout}
                  handleApply={handleApplySlideoutChanges}
                />
              )}
            </Foldout>
          </>
        )}
      </FormSection>
      <ButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          type='submit'
          disabled={!dirty || !isValid || isSubmitting}
          text={id ? 'Update Client' : 'Create Client'}
        />
      </ButtonGroup>
    </Form>
  );
}

ClientForm.propTypes = {
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.instanceOf(Client)),
    clientType: PropTypes.instanceOf(Object),
    groupTypes: PropTypes.arrayOf(PropTypes.instanceOf(GroupType)),
    id: PropTypes.string,
    leaf: PropTypes.bool,
    mfaConfig: PropTypes.shape({
      enabled: PropTypes.bool,
      allowedDependentFactors: PropTypes.arrayOf(PropTypes.object),
      allowedIndependentFactors: PropTypes.arrayOf(PropTypes.object),
      default: PropTypes.object,
    }),
    ssoEnabled: PropTypes.bool,
  }),
};

export default ClientForm;

const ClientCheckbox = styled(Checkbox)`
  margin: 8px 0px 24px 0px;
`;
