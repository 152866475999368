import { useEffect, useState } from 'react';

export default function useLinger(val: boolean, delay: number) {
  const [lingerVal, setLingerVal] = useState(false);

  useEffect(() => {
    if (val) {
      setLingerVal(true);
      return;
    }
    const timeout = setTimeout(() => {
      setLingerVal(false);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, val]);

  return lingerVal;
}
