import GroupType from './GroupType';

export type ClientGroupTypeConfig = {
  launchDate: Date | null;
  projectedDischarge: {
    enabled: boolean;
  };
  utilizationManagement: {
    enabled: boolean;
    planTypeClassification: string[];
  };
  caseManager: boolean;
  utilizationManager: boolean;
};
interface IClientGroupTypeOptions {
  id: string;
  config: ClientGroupTypeConfig;
  groupTypeId: string;
  groupType: GroupType;
}

export const defaults: IClientGroupTypeOptions = {
  id: '',
  config: {
    launchDate: null,
    projectedDischarge: {
      enabled: false,
    },
    utilizationManagement: {
      enabled: false,
      planTypeClassification: [],
    },
    caseManager: false,
    utilizationManager: false,
  },
  groupTypeId: '',
  groupType: new GroupType(),
};

export default class ClientGroupType implements IClientGroupTypeOptions {
  id: string;
  groupTypeId: string;
  groupType: GroupType;
  config: ClientGroupTypeConfig;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.config = {
      ...opts.config,
      // We need to append a timestamp to the date so that it is shown correctly in the UI
      // when the browser tries to convert the string to a date. We do something similar
      // with projected discharge dates in the web repo
      launchDate: parseLaunchDate(opts.config.launchDate),
    };
    this.groupTypeId = opts.groupTypeId;
    this.groupType = new GroupType(opts.groupType);
  }
}

export const parseLaunchDate = (launchDate: null | Date) => {
  if (!launchDate) return null;
  if (launchDate instanceof Date) return launchDate;

  // Set time to end of day
  return new Date(`${launchDate}T23:59:00.000`);
};
